var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container" }, [
    _c("div", { staticClass: "widget-container" }, [
      _c(
        "div",
        { staticClass: "grid" },
        [_c("users-widget"), _c("datetime-widget")],
        1
      ),
    ]),
    _c(
      "span",
      { staticClass: "copyright" },
      [
        _vm._v(" Made with "),
        _c("heart", { attrs: { width: 12 } }),
        _vm._v(" by CodeFish Studio "),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }