var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "users" },
    _vm._l(_vm.users, function (user) {
      return _c("user-block", {
        key: user.id + "_user-tracker",
        attrs: { user: user },
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }