var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "datetime" }, [
    _c("h1", [
      _c("span", [_vm._v(_vm._s(_vm.hours))]),
      _vm._v(" : "),
      _c("span", [_vm._v(_vm._s(_vm.minutes))]),
    ]),
    _c("h3", [_vm._v(_vm._s(_vm.date))]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }