var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "user-block" },
    [
      _c(
        "div",
        { staticClass: "head" },
        [
          _c("avatar", {
            staticClass: "avatar desktop-only",
            attrs: { user: _vm.user, "hide-tooltip": "" },
          }),
          _c("span", { staticClass: "name" }, [_vm._v(_vm._s(_vm.user.name))]),
        ],
        1
      ),
      _c("tracking-block", { attrs: { user: _vm.user, editable: false } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }